.container-utilization {
    background: #FFFFFF;
    min-height: 590px;
    height: auto;
    padding-top: 93px;
    padding-bottom: 0;
  }
  .container-utilization .link-home {
    float: right;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #0B545C;
    padding: 40px 0 0;
    margin-right: -40px;
  }
  .container-utilization .link-home:hover {
    cursor: pointer;
    color: #5aa5ae;
  }
  @media screen and (max-width: 767px) {
    .container-utilization .link-home {
      display: none;
    }
  }


  .container-utilization form {
    text-align: center;
    color: #3A3A3A;
  }
  .container-utilization form .title {
    width: 100%;
    padding-top: 80px;
    padding-bottom: 20px;
    margin: 0 !important;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;
    color: #303034;
  }
  @media screen and (max-width: 812px) {
    .container-utilization form .title {
      font-size: 16px;
      padding-top: 50px;
    }
  }
  .container-utilization .input-login,
  .container-utilization .input-button {
    margin: 58px auto 70px auto;
    display: block;
    border: 0;
    width: 370px !important;
    width: 272px;
    height: 38px;
    background-color: #F21C45;
    color: white;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 400;
    border-radius: 21px;
    cursor: pointer;
    border: 0;
  }
  .container-utilization .input-login div,
  .container-utilization .input-button div {
    text-decoration: none;
    color: white;
    display: block;
    width: 100%;
    height: 38px;
    padding: 10px;
    line-height: 18px;
    font-size: 16px;
  }
  @media screen and (max-width: 767px) {
    .container-utilization .input-login,
  .container-utilization .input-button {
      width: 100%;
    }
  }
  @media screen and (max-width: 576px) {
    .container-utilization .input-login,
  .container-utilization .input-button {
      width: 85%;
    }
  }
  .container-utilization .input-login:hover,
  .container-utilization .input-button:hover {
    background-color: #D6052d;
  }
  @media screen and (max-width: 767px) {
    .container-utilization .input-login,
  .container-utilization .input-button {
      margin-top: 69px;
      width: 90% !important;
    }
  }
  .container-utilization .field-mail,
  .container-utilization .field-password {
    position: relative;
    width: 370px;
    margin: 20px auto 0;
  }
  .container-utilization .field-mail p,
  .container-utilization .field-password p {
    font-size: 14px;
    line-height: 17px;
    margin: 8px 0;
    text-align: left;
    color: #3A3A3A;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 400;
  }

  .see-password {
    position: absolute;
    top: 9px;
    right: 8px;
    background: transparent url(./img/sprites-login.svg) no-repeat scroll -64px 0;;
    width: 25px;
    height: 17px;
    cursor: pointer;
    padding: 12px;
  }

  .see-password.show-password {
    background-position: -33px 1px;
  }

  .container-utilization .field-mail span,
  .container-utilization .field-password span {
    text-align: left;
  }
  .container-utilization .field-mail .see-password,
  .container-utilization .field-password .see-password {
    top: 32px;
  }
  .container-utilization .field-mail a,
  .container-utilization .field-password a {
    left: -100px;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 500;
    color: #0B545C;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
    text-decoration: none;
    top: 7px;
    position: relative;
  }

  .container-utilization .field-password a:hover {
    cursor: pointer;
    color: #5aa5ae;
  }

  @media screen and (max-width: 767px) {
    .container-utilization .field-mail a,
  .container-utilization .field-password a {
      left: 0;
      float: left;
    }
  }
  .container-utilization .field-mail input,
  .container-utilization .field-password input {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    .container-utilization .field-mail,
  .container-utilization .field-password {
      width: 90%;
    }
  }
  .container-utilization .note {
    background: #F7F7F7;
    height: 590px;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    color: #5A5A5A;
    padding: 15% 22px;
    overflow: hidden;
  }

  .container-utilization .note u {
    font-family: 'Maven Pro', sans-serif;
    font-weight: 500;
  }
  .container-utilization .note b {
    font-family: 'Maven Pro', sans-serif;
    font-weight: 500;
    padding-bottom: 15px;
  }
  @media screen and (max-width: 767px) {
    .container-utilization .note {
      height: auto;
      padding: 35px;
    }
  }
  .container-utilization .password-restore .title {
    width: 100%;
    padding-top: 100px;
    padding-bottom: 20px;
    margin: 0 !important;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;
    color: #303034;
  }
  @media screen and (max-width: 812px) {
    .container-utilization .password-restore .title {
      font-size: 16px;
      padding-top: 50px;
    }
  }
  .container-utilization .password-restore .input-button {
    margin-top: 30px;
  }
  .container-utilization .transactions {
    text-align: center;
    justify-content: center;
  }
  .container-utilization .transactions .title {
    width: 100%;
    padding-bottom: 20px;
    margin: 0 !important;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;
    color: #303034;
    padding-top: 55px;
  }
  @media screen and (max-width: 812px) {
    .container-utilization .transactions .title {
      font-size: 16px;
      padding-top: 50px;
    }
  }
  .container-utilization .transactions .information {
    background: #F2F2F2;
    width: 370px;
    margin: 8px auto;
    padding-inline-start: 0;
    padding: 10px;
  }
  .container-utilization .transactions .information li {
    list-style: none;
    text-align: left;
    font-size: 15px;
    line-height: 23px;
    font-family: 'Barlow Condensed', sans-serif;
    color: #434343;
    overflow: hidden;
  }
  .container-utilization .transactions .information li span {
    float: right;
    width: 70%;
    left: 0;
    color: #000325;
  }
  @media screen and (max-width: 767px) {
    .container-utilization .transactions .information {
      width: 85% !important;
    }
  }
  .container-utilization .transactions .input-register {
    margin: 40px auto 100px;
    width: 370px !important;
    width: 272px;
    height: 38px;
    background-color: #F21C45;
    color: white;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 400;
    border-radius: 21px;
    cursor: pointer;
    border: 0;
  }
  .container-utilization .transactions .input-register div {
    text-decoration: none;
    color: white;
    display: block;
    width: 100%;
    height: 38px;
    padding: 10px;
    line-height: 18px;
    font-size: 16px;
  }
  @media screen and (max-width: 767px) {
    .container-utilization .transactions .input-register {
      width: 100%;
    }
  }
  @media screen and (max-width: 576px) {
    .container-utilization .transactions .input-register {
      width: 85%;
    }
  }
  .container-utilization .transactions .input-register:hover {
    background-color: #D6052d;
  }
  @media screen and (max-width: 767px) {
    .container-utilization .transactions .input-register {
      width: 90% !important;
    }
  }
  .container-utilization .field-id,
  .container-utilization .field-value {
    width: 370px;
    margin: 0 auto;
  }
  .container-utilization .field-id p,
  .container-utilization .field-value p {
    font-size: 14px;
    line-height: 17px;
    margin: 8px 0;
    text-align: left;
    color: #3A3A3A;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 400;
  }

  .container-utilization .field-value .select-id {
    width: 25%;
    height: 35px;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #434343;
    float: left;
  }

  .field-id .select-id  {
    width: 25%;
    float: left;
    position: relative;
  }

  .field-id .select-id::after {
    content: '';
    position: absolute;
    top: 12px;
    right: 10px;
    width: 8px;
    height: 8px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border-bottom: 1.5px solid #0B545C;
    border-right: 1.5px solid #0B545C;
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
  input[type=number] { -moz-appearance:textfield; }

  .field-id .select-id select {
    background: white;
    width: 100%;
    height: 35px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 0 35px 0 13px;
    font-size: 16px;
    color: #434343;
    cursor: pointer;
    font-family: 'Maven Pro', sans-serif !important;
    border: 1px solid #9d9d9d;
    border-radius: 5px !important;
  }


  .input-search-id {
    width: 70%;
    float: right;
    position: relative;
  }

  .input-search-id  .input-id {
    width: 100% !important;
  }
  
  .input-search-id .alert-error {
    text-align: left;
  }

  .container-utilization .field-value .input-id {
    width: 70%;
    float: right;
  }
  .container-utilization .field-id .input-value,
  .container-utilization .field-value .input-value {
    width: 90% !important;
    float: right;
    right: 0;
    position: relative;
  }
  .container-utilization .field-id .input-value.disabled,
  .container-utilization .field-value .input-value.disabled {
    background-color: #F0F0F0 !important;
  }
  @media screen and (max-width: 767px) {
    .container-utilization .field-id,
  .container-utilization .field-value {
      width: 85%;
    }
  }
  .container-utilization .field-value span {
    float: left;
    width: 10%;
    height: 35px;
    line-height: 35px;
    color: #434343;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 500;
    font-size: 16px;
  }
  .container-utilization .field-value span.alert-error {
    color: #F21C45;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    height: 13px;
    width: 90%;
    float: right;
    text-align: left;
  }
  .container-utilization .field-value p:nth-of-type(2) {
    float: left;
    left: 10%;
    top: 0;
    position: relative;
    font-size: 11px;
    line-height: 13px;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 500;
  }
  .container-utilization .field-benefits {
    width: 370px;
    margin: 10px auto 20px;
  }
  .container-utilization .field-benefits p {
    font-size: 14px;
    line-height: 17px;
    margin: 8px 0;
    text-align: left;
    color: #3A3A3A;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 400;
  }

  .container-utilization .field-benefits .select {
    position: relative;
  }

  .container-utilization .field-benefits .select::after {
    content: '';
    position: absolute;
    top: 12px;
    right: 10px;
    width: 8px;
    height: 8px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border-bottom: 1.5px solid #0B545C;
    border-right: 1.5px solid #0B545C;
  }
  .container-utilization .field-benefits select {
    width: 100%;
    height: 35px;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    border-radius: 5px;
    border: 1px solid #9d9d9d;
    padding-left: 8px;
    padding-right: 25px;
    color: #434343;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
  }
  .container-utilization .field-benefits .select select {
    background-color: #FFFFFF !important;
  }
  .container-utilization .field-benefits .select.disabled::after {
    border-bottom: 1.5px solid #9D9D9D;
    border-right: 1.5px solid #9D9D9D;
  }
  .container-utilization .field-benefits .select.disabled  select {
    background-color: #F0F0F0 !important;
    color: #808080;
  }
  .container-utilization .field-benefits .alert-error {
    text-align: left;
  }
  @media screen and (max-width: 767px) {
    .container-utilization .field-benefits {
      width: 85% !important;
    }
  }
  @media screen and (max-width: 812px) {
    .container-utilization {
      padding-top: 50px;
    }
  }

  input {
    border: 1px solid #9D9D9D;
    box-shadow: none;
    border-radius: 5px;
    color: #434343;
    font-family: 'Maven Pro', sans-serif !important;
    font-weight: 400;
    height: 35px;
    font-size: 16px;
    width: 370px;
    height: 35px;
    text-decoration-line: none;
    outline: none !important;
    outline-width: 0 !important;
  }
  @media screen and (max-width: 991px) {
    input {
      width: 320px;
    }
  }
  @media screen and (max-width: 767px) {
    input {
      width: 100%;
      font-size: 14px;
    }
  }

  input[placeholder] {
    padding: 8px 35px 8px 12px;
  }

  .alert-error {
    display: none;
    color: #F21C45;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    padding: 5px 0;
  }
  @media screen and (max-width: 576px) {
    .alert-error {
      font-size: 11px;
    }
  }
  .password-restore {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    padding-top: 17px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.password-restore .title {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 20px;
  margin: 0 !important;
  font-family: 'Maven Pro', sans-serif;
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  color: #303034;
}
@media screen and (max-width: 812px) {
  .password-restore .title {
    font-size: 16px;
    padding-top: 50px;
  }
  .field-id .select-id select {
    font-size: 14px;
    padding-right: 40%;
  }
}
.password-restore .input-button {
  margin-top: 30px;
}

.disabled-input {
  background: #ACACAC !important;
  font-size: 17px;
  line-height: 18px;
}

.icon-search {
  position: absolute;
  right: 0;
  top: 0;
  width: 35px;
  height: 35px;
  background: url('./img/icon-search.svg');
  background-repeat: no-repeat;
  background-position: 4px -24px;
}

.icon-search:hover {
  cursor: pointer;
  opacity: 0.68;
}

.icon-search::before {
  content: '';
  display: block;
  height: 24px;
  width: 1px;
  background: #D6D6D6;
  left: 0;
  top: 6px;
  position: absolute;
}
.ImagenLogo {
    display: block;
    position: absolute;
    cursor: pointer;
    width: 180px;
    height: 180px;
    left: 0;
    top: -72px;
    background-color: #FFFFFF;
    background-image: url("./img/logo-header.svg");
    background-size: 126px 50px;
    background-repeat: no-repeat;
    background-position: center 90px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -webkit-box-shadow: 6px 0px #000325;
    -moz-box-shadow: 6px 0px #000325;
    box-shadow: 6px 0px #000325;
    z-index: 8;
  }

  .ImagenLogo a {
    display: block;
    height: 100%;
    width: 100%;
  }
  @media screen and (max-width: 991px) {
    .ImagenLogo {
      left: -10px;
    }
  }
  @media screen and (max-width: 860px) {
    .ImagenLogo {
      left: -17px;
    }
  }
  @media screen and (max-width: 812px) {
    .ImagenLogo {
      top: 8px;
      width: 80px;
      height: 35px;
      left: 45px;
      position: fixed;
      background-color: transparent;
      background-image: url("./img/logo-header-fixed.svg");
      background-size: 80px;
      background-repeat: no-repeat;
      background-position: left top;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }

.header-utilization {
    background: #000325;
    position: fixed;
    top: 0;
    left: 0;
    max-width: 100% !important;
    width: 100%;
    height: 93px;
    z-index: 99;
  }
  .header-utilization .container {
    padding-top: 0 !important;
    position: relative;
  }
  .header-utilization .container .ImagenLogo {
    width: 200px;
    height: 200px;
    background-position: center 100px;
  }

  @media screen and (max-width: 991px) {
    .header-utilization .container .ImagenLogo {
      left: -75px;
    }
  }
  @media screen and (max-width: 812px) {
    .header-utilization .container .ImagenLogo {
      width: 80px;
      height: 35px;
      background-position: left top;
      left: 24px;
    }
  }
  .header-utilization .container .title {
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 93px;
    margin: 0;
    text-align: center;
    padding-left: 40px;
  }
  @media screen and (max-width: 991px) {
    .header-utilization .container .title {
      font-size: 20px;
    }
  }
  @media screen and (max-width: 812px) {
    .header-utilization .container .title {
      display: none;
    }
  }
  .header-utilization .container .close-session {
    float: right;
    position: relative;
    top: -58px;
    color: #76E8F4;
    background: transparent;
    border: none;
    outline: none;
    font-size: 16px;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 400;
    line-height: 20px;
  }
  .header-utilization .container .close-session:hover {
    cursor: pointer;
    color: #9af2fb;
  }
  @media screen and (max-width: 812px) {
    .header-utilization .container .close-session:hover {
      color: #5aa5ae;
    }
  }
  @media screen and (max-width: 991px) {
    .header-utilization .container .close-session {
      font-size: 15px;
      right: -30px;
    }
  }
  @media screen and (max-width: 812px) {
    .header-utilization .container .close-session {
      color: #0B545C;
      top: 15px;
      font-family: 'Maven Pro', sans-serif;
      font-weight: 500;
      line-height: 20px;
      right: 26px;
      position: fixed;
    }
  }
  @media screen and (max-width: 812px) {
    .header-utilization {
      background: #FFFFFF;
      height: 50px;
      box-shadow: 0 2px 4px 0 rgba(170, 170, 170, 0.5);
    }
  }

body {
  font-family: 'Maven Pro', sans-serif;
}

@import url('https://fonts.googleapis.com/css?family=Barlow+Condensed:600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Maven+Pro&display=swap');

.alert-message {
  width: 370px;
  height: auto;
  color: #721c24;
  border-radius: 5px;
  border: solid 1px #fec3c8;
  background-color: #f8d7da;
  position: relative;
  font-size: 14px;
  margin: 16px auto -40px auto;
  z-index: 98;
}

.alert-message:before  {
  display: block;
  content: 'x';
  width: 20px;
  height: 20px;
  line-height: 8px;
  background:#bf3c3c;
  border-radius: 50%;
  color:  white;
  font-size: 15px;
  padding: 5px;
  position: absolute;
  left: 14px;
  top: 29%;
}

.alert-message p {
  padding: 12px 30px 12px 45px;
  text-align: left;
  margin: 0;
}

.close-alert {
  position: absolute;
  right: 0;
  top: 0;
  color: #721c24;
  font-weight: bold;
  font-size: 17px;
  padding: 8px 12px;
  cursor: pointer;
}

.title-form {
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 36px;
  margin-left: auto;
  margin-right: auto;
  width: 480px;
  position: relative;
  background: #ffffff;
  text-align: center;
}

.title-form:before {
  content: '';
  width: 72px;
  height: 46px;
  background: #ffffff;
  position: absolute;
  top: -19px;
  left: -37px;
  border-color: #9097eb;
  border: 2px solid #9097eb;
  border-radius: 14px 5px 5px 5px;
  z-index: -9;
}

.form-contact {
  max-width: 770px;
  margin-left: auto;
  margin-right: auto;
}

.field-name-contact {
  margin-top: 39px;
}

.form-contact  p {
  font-family: 'Maven Pro', sans-serif;
  font-size: 14px;
  color: #808080;
  margin: 7px 0;
}

.input-name-contact {
  width: 99%;
  height: 15px;
  border-radius: 5px;
  border: solid 1px #9d9d9d;
  font-family: 'Maven Pro', sans-serif;
  font-size: 14px;
  color: #434343;
  margin-right: auto;
  margin-left: auto;
  padding: 9px 0 9px 8px;
}

.select-type-id,
.input-number-id,
.input-mail-contact,
.input-telephone-contact,
.select-category,
.select-case
{
  width: 370px;
  height: 15px;
  border-radius: 5px;
  border: solid 1px #9d9d9d;
  background: #ffffff;
  font-family: 'Maven Pro', sans-serif;
  font-size: 14px;
  color: #434343;
  padding: 9px 0 9px 8px;
}

.input-mail-contact,
.input-telephone-contact,
.input-number-id {
  width: 362px;
}
.select-type-id,
.select-category,
.select-case {
  height: 35px;
}

.textarea-contact {
  font-family: 'Maven Pro', sans-serif;
  font-size: 14px;
  color: #434343;
  width: 750px;
  height: 186px;
  padding: 10px;
  border-radius: 5px;
  border: solid 1px #9d9d9d;
}

.field-terms-conditions-contact {
  font-family: 'Maven Pro', sans-serif;
  font-size: 12px;
  line-height: 15px;
  color: #434343;
  margin-top: 32px;
  position: relative;
}

.field-terms-conditions-contact a {
  color: #1e73c2 !important;
  cursor: pointer;
}

.checkbox-terms-conditions {
  float: left;
  width: 20px;
  height: 20px;
  margin: 0 14px 0 0;
}

.button-send {
  width: 225px;
  height: 38px;
  border-radius: 19px;
  border: 0;
  background: #f21c45;
  color: #ffffff;
  font-size: 16px;
  margin: 30px auto;
  display: block;
  cursor: pointer;
}

input,
textarea,
select
{
  outline: none!important;
  outline-width: 0!important;
}

.col-form-left  {
  margin-top: 13px;
  margin-right: auto;
  margin-left: 0;
  position: relative;
}

.col-form-rigth {
  margin-top: 13px;
  margin-right: 0;
  margin-left: auto;
  position: relative;
}

.alert-error {
  display: none;
  position: relative;
  color: red;
  font-size: 12px;
}

.col-form-auto {
  margin-top: 13px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  position: relative;
}

.row-form {
  display: flex;
  flex-wrap: wrap;
}

.field-textarea-contact {
  padding-top: 20px;
}

.col-form-auto.error-form {
  height: auto;
}

.error-form {
  height: 80px;
}

.error-form  .alert-error {
  display: block;
}

.error-form input,
.error-form select,
.error-form textarea  {
  border: 1px solid red;
}

.text-mail-confirmation {
  font-size: 16px;
  line-height: 26px;
  color: #000325;
  padding-top: 88px;
  padding-bottom: 36px;
  margin-bottom: 36px;
  border-bottom: dashed 1px #979797;
  text-align: center;
}

.mail-confirmation a {
  color: #0b545c;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  width: 165px;
  display: flex;
}

.mail-confirmation {
  display: block ;
}

@media screen and (max-width: 762px) {

  .alert-message {
    width: 90%;
    margin-bottom: -20px;
  }
  .form-contact {
    width: 90%;
  }
  .textarea-contact {
    width: 350px;
  }
  .checkbox-terms-conditions {
    width: 20px !important;
  }
}

@media screen and (max-width: 650px) {

  .form-contact,
  .col-form-left,
  .col-form-rigth {
    width: 100%;
  }
  
  .alert-message {
    width: 90%;
  }
  .checkbox-terms-conditions {
    width: 20px !important;
  }

  select {
    width: 100% !important;
  }

  .textarea-contact {
    width: 95%;
  }

  .title-form {
    font-size: 18px;
    width: 100%;
  }
  .title-form:before {
    display: none;
  }
}
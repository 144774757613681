.shipping-alert {
    height: auto;
    width: 370px;
    border: 1px solid #D4EDDA;
    border-radius: 5px;
    background-color: #FAFEFB;
    margin: 20px auto -80px;
    position: relative;
    z-index: 98;
  }
  .shipping-alert:before {
    display: block;
    content: "";
    width: 18px;
    height: 18px;
    background: url(./boton-alerta.svg);
    position: absolute;
    left: 10px;
    top: 35%;
  }
  .shipping-alert.transactions-alert {
    margin-bottom: 0;
  }
  .shipping-alert.transactions-alert:before {
    top: 5%;
  }
  .shipping-alert.transactions-alert .title {
    color: #303034;
    font-size: 15px;
    text-align: center;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 500;
    padding-top: 27px;
  }
  .shipping-alert.transactions-alert .name,
  .shipping-alert.transactions-alert .type {
    color: #434343;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    padding: 0;
  }
  .shipping-alert.transactions-alert .type {
    font-style: italic;
  }
  .shipping-alert.transactions-alert ul {
    padding-inline-start: 37%;
    margin-top: 25px;
    margin-bottom: 15px;
  }
  .shipping-alert.transactions-alert li {
    list-style: none;
    height: 27px;
    color: #000000;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 27px;
  }
  .shipping-alert.transactions-alert li:last-of-type {
    font-family: 'Maven Pro', sans-serif;
    font-weight: 500;
  }
  .shipping-alert.transactions-alert li span {
    text-align: right;
  }
  .shipping-alert.transactions-alert li span:first-of-type {
    float: left;
    width: 40%;
  }
  .shipping-alert.transactions-alert li span:nth-of-type(2) {
    float: right;
    padding-right: 29px;
  }
  .shipping-alert p {
    font-family: 'Maven Pro', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #0b545c;
    margin: auto 35px;
    padding: 13px;
  }
  .shipping-alert .close-alert {
    position: absolute;
    right: 0;
    top: 0;
    color: #358447;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 700;
    font-size: 17px;
    padding: 6px 12px;
    cursor: pointer;
  }

  @media screen and (max-width: 812px) {
    .shipping-alert {
      margin-bottom: -40px;
      margin-top: 30px;
    }
  }
  @media screen and (max-width: 767px) {
    .shipping-alert {
      width: 80%;
    }
  }
  @media screen and (max-width: 575px) {
    .shipping-alert {
      width: 85%;
    }
    .shipping-alert.transactions-alert ul {
      padding-inline-start: 23%;
    }
  }
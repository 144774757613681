.link-home {
    float: right;
    font-family: 'Maven Pro', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #0B545C;
    padding: 40px 0 0;
    margin-right: -40px;
    text-decoration: none;
    border: none;
    background: transparent;
}

.link-home:hover {
    cursor: pointer;
    color: #5aa5ae;
}

  @media screen and (max-width: 767px) {
    .container-utilization .link-home {
      display: none;
    }
  }
.footer-utilization {
    margin: 0;
    background: #000325;
    position: relative;
    bottom: 0;
    left: 0;
    max-width: 100%;
    width: 100%;
    height: 127px;
    z-index: 99;
  }
  .footer-utilization p {
    font-family: 'Maven Pro', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    text-align: center;
    line-height: 20px;
    margin: 0;
    overflow: hidden;
    padding: 45px 0;
  }
  @media screen and (max-width: 991px) {
    .footer-utilization p {
      font-size: 13px;
      line-height: 16px;
      margin: 0;
      padding: 35px 15px;
    }
  }
  @media screen and (max-width: 991px) {
    .footer-utilization {
      height: 100px;
    }
  }